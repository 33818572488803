import React from 'react'
import Layout from '../components/layout';

const HybridAppSecurityChecklistPage = () => (
  <Layout>
    <div>
      <div
        className="hero is-large"
        style={{
          backgroundImage: `url(https://images.unsplash.com/photo-1503024572063-b3c621a2d424?ixlib=rb-0.3.5&s=b18892f06a54a3b3c2aadb78131d27bb&auto=format&fit=crop&w=1489&q=80)`,
          backgroundPosition: `center center`,
          backgroundSize: `cover`,
          backgroundColor: `#7a7a7a`,
          backgroundBlendMode: `multiply`,
          textAlign: `center`
        }}
      >
        <div className="hero-body">
          <h1 style={{ color: `white`, fontSize: `4em`, margin: `1em auto` }}>Secure your app today.</h1>
          <p style={{ color: `white` }}>Download the free Hybrid App Security Checklist to make sure your app is as secure as it can be.</p>
          <form action='https://ionic-newsletter.curated.co/email_subscribers' id='embedded-subscribe-form' method='post'>
            <div className="field has-addons">
              <div className="control">
                <input className="input" id='subscribe-email' name='email' type='email' />
              </div>
              <div className="control">
                <input className="button is-info" type='submit' onclick="this.form.submit(); this.disabled=true; this.value='Sending...';" value='Subscribe' />
              </div>
            </div>
            <input name='form_dom_id' value='embedded-subscribe-form' type='hidden' />
          </form>
        </div>
      </div>
    </div>
  </Layout>
)

export default HybridAppSecurityChecklistPage
